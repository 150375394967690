import { Box } from "@mui/material";
import { Form } from "formik";
import styled from "styled-components";

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.interface.paper};
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.space(60)};
  padding-bottom: ${({ theme }) => theme.space(90)};

  ${(p) => p.theme.breakpoints.down("lg")} {
    border-radius: ${({ theme }) => theme.radius[30]};
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
`;

export const SSOButtons = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space(30)};
`;

export const ErrorContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoadingContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DividerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.space(70)};
`;

export const NextContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.down("xl")} {
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.space(60)};
  }
`;

export const LogInAndBackButton = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.space(40)};
`;

export const GetInTouchContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space(20)};
  width: 100%;
  white-space: nowrap;

  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0 ${({ theme }) => theme.space(20)};
  }
`;
