import { Box } from "@mui/material";
import styled from "styled-components";

export const LoginPage = styled(Box)`
  display: flex;
  min-height: 100vh;
  height: fit-content;
  width: 100vw;
  position: relative;

  ${(p) => p.theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ForgottenPasswordPage = styled(LoginPage)`
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-image: url("${process.env
    .NEXT_PUBLIC_BX_CDN_URL}/authBackground.jpg");
`;

export const LHSContainer = styled(Box)`
  display: flex;
  width: 50%;
  display: flex;
  justify-content: center;

  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const RHSContainer = styled(Box)`
  display: flex;
  width: 50%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: transparent;

  ${(p) => p.theme.breakpoints.down("md")} {
    width: 100%;
    height: 400px;
    position: absolute;
    top: 60px;
    z-index: -1;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: 250px;
  }

  background-image: url("${process.env
    .NEXT_PUBLIC_BX_CDN_URL}/authBackground.jpg");
`;

export const IconButtonContainer = styled(Box)<{ stage: "email" | "password" }>`
  display: flex;
  align-items: center;
  z-index: 1;
  height: 100%;
  padding: ${({ theme }) => theme.space(40)};
  border-right: 1px solid
    ${({ theme }) => theme.palette.primary.outlinedRestingBorder};
`;

export const HeaderContainer = styled(Box)<{ stage: "email" | "password" }>`
  display: flex;
  position: absolute;
  top: 0;
  height: 76px;
  width: 100vw;
  align-items: center;
  gap: ${({ theme, stage }) => (stage === "password" ? theme.space(60) : 0)};
  padding-left: ${({ theme, stage }) =>
    stage === "password" ? 0 : theme.space(60)};
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.primary.outlinedRestingBorder};

  ${(p) => p.theme.breakpoints.down("md")} {
    height: 60px;
    gap: ${({ theme, stage }) => (stage === "password" ? theme.space(50) : 0)};
    padding-left: ${({ theme, stage }) =>
      stage === "password" ? 0 : theme.space(50)};
  }
`;

export const LoginContainer = styled(Box)`
  margin: 0 ${({ theme }) => theme.space(120)};
  margin-top: calc(${({ theme }) => theme.space(120)} + 76px);
  width: 100%;
  height: fit-content;
  width: 472px;

  ${(p) => p.theme.breakpoints.up("lg")} {
    min-width: 404px;
  }
  ${(p) => p.theme.breakpoints.down("lg")} {
    min-width: 343px;
  }
  ${(p) => p.theme.breakpoints.down("md")} {
    padding: ${({ theme }) => theme.space(70)} ${({ theme }) => theme.space(80)};
    border-radius: ${({ theme }) => theme.radius[20]};
    min-width: 450px;
    background: ${({ theme }) => theme.palette.interface.paper};
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => theme.space(70)};
    min-width: 375px;
  }
`;

export const FooterContainer = styled(Box)`
  position: absolute;
  bottom: -1;
  width: 100%;
`;
